//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { required, minLength, symbols, FormMixin, default as UiForm } from "@/components/Form/Form";
export default {
  mixins: [ModalMixin, FormMixin],
  components: {
    Modal,
    UiForm
  },
  props: {},
  data() {
    return {
      active_inputs: [{
        title: "Текущий пароль",
        name: "user_password_current",
        placeholder: "Введите текущий пароль",
        type: "password",
        error_text: "",
        show_error: false,
        show_success: false,
        show_password: false,
        model: "",
        active: false,
        touched: false,
        validators: [required()],
        silentSuccess: true
      }, {
        title: "Новый пароль",
        name: "user_password",
        placeholder: "Установите новый пароль",
        type: "password",
        error_text: "",
        show_error: false,
        show_success: false,
        show_password: false,
        model: "",
        active: false,
        touched: false,
        validators: [required(), minLength(5, "Слишком короткий пароль"), symbols()]
      }],
      loading: false
    };
  },
  mounted() {},
  methods: {
    onOpen() {},
    async submit({
      close
    }) {
      const isValid = await this.validate(true);
      if (!isValid) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch("user/changePassword", {
          user_password_current: this.active_inputs.find(i => i.name === "user_password_current").model.trim(),
          user_password: this.active_inputs.find(i => i.name === "user_password").model.trim()
        });
        close();
        this.$emit("complete");
        this.loading = false;
      } catch (e) {
        var _e$response;
        if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 403) {
          const input = this.active_inputs.find(i => i.name === "user_password_current");
          input.error_text = "Неправильный пароль";
          input.show_error = true;
          input.show_success = false;
        }
        this.loading = false;
      }
    }
  },
  computed: {
    valid() {
      return true;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Сменить пароль",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  }
};