//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalChangePassword from "@/components/User/ModalChangePassword";
import ModalRemoveTgAccountName from "@/components/modals/ModalRemoveTgAccountName";
import Alert from "@/components/Alert/Alert";
export default {
  meta: {
    title: "Настройки аккаунта"
  },
  components: {
    ModalChangePassword,
    Alert,
    ModalRemoveTgAccountName
  },
  data() {
    return {
      passwordChanged: false,
      childsInfo: null,
      tgAccountName: "",
      tgAccountNameInput: "",
      tgAccountNameEditing: false,
      tgAccountNameError: false,
      listOnly: process.env.VUE_APP_DEMO_TG_BOT === "false" ? false : true
    };
  },
  created() {
    return Promise.all([this.fetchTestUsers(), this.$store.dispatch("user/getUser", {
      force: true
    }), this.loadChildsInfo(), this.getTgName()]);
  },
  methods: {
    async fetchTestUsers() {
      try {
        const response = await fetch("/test-users.json");
        const json = await response.json();
        this.testUsers = json.users;
      } catch (error) {
        console.error("Failed to fetch users:", error);
        return {};
      }
    },
    async getTgName() {
      const response = await this.$store.dispatch("user/getTgName");
      this.tgAccountName = response.data[0];
    },
    async editTgAccountName() {
      this.tgAccountNameEditing = true;
      this.tgAccountNameInput = this.tgAccountName;
      this.tgAccountName = "";
    },
    async saveTgAccountName() {
      try {
        await this.$store.dispatch("user/setTgName", {
          name: this.tgAccountNameInput
        });
        this.tgAccountNameEditing = false;
        this.tgAccountName = this.tgAccountNameInput;
        this.tgAccountNameError = false;
      } catch (e) {
        this.tgAccountNameError = true;
      }
    },
    deleteTgAccountName() {
      const onComplete = async () => {
        this.tgAccountNameInput = "";
        this.tgAccountName = "";
      };
      this.$refs.modalRemoveTgAccountName.open({
        item: {
          title: this.tgAccountName
        },
        onComplete
      });
    },
    async loadChildsInfo() {
      this.childsInfo = await this.$store.dispatch("user/getChilds");

      //console.log('this.childsInfo=',this.childsInfo)
    },

    async onInviteChild() {
      await this.$modal.process(() => import("@/components/User/ModalInviteChild"));
      await this.loadChildsInfo();
    },
    async onDisableChild(item) {
      await this.$modal.process(() => import("@/components/User/ModalDisableChild"), {
        linkId: item.link.id,
        email: item.link.child.user_email_address
      });
      await this.loadChildsInfo();
    },
    async onEnableChild(item) {
      await this.$store.dispatch("user/enableChild", {
        id: item.link.id
      }), await this.loadChildsInfo();
    }
  },
  computed: {
    inTheList() {
      var _this$testUsers;
      return (_this$testUsers = this.testUsers) === null || _this$testUsers === void 0 ? void 0 : _this$testUsers.includes(this.$store.state.user.user.user_id);
    },
    showNewVariant() {
      return this.listOnly ? this.inTheList : true;
    },
    user() {
      //console.log('this.$store.state.user.user=',this.$store.state.user.user)

      return this.$store.state.user.user;
    },
    countConnected() {
      var _this$childsInfo;
      if (!this.childsInfo) {
        return null;
      }
      return ((_this$childsInfo = this.childsInfo) === null || _this$childsInfo === void 0 ? void 0 : _this$childsInfo.childs.filter(t => !t.disabled).length) + 1;

      /* &:
      let childs = this.childsInfo?.childs;
      return childs && childs.length ?
          childs.filter((t) => !t.disabled).length + 1
          : 1 ;
      */
    },

    countAvailable() {
      if (!this.childsInfo) {
        return null;
      }
      return this.childsInfo.users_quota + 1;
    },
    canEnable() {
      return this.childsInfo.users_quota_remaining;
    },
    childs() {
      var _this$childsInfo2;
      if (!this.childsInfo) {
        return null;
      }

      //@V:
      return [...((_this$childsInfo2 = this.childsInfo) === null || _this$childsInfo2 === void 0 ? void 0 : _this$childsInfo2.childs)].sort((a, b) => {
        return (b.disabled ? 0 : 1) - (a.disabled ? 0 : 1);
      });

      /* &:
      let childs = this.childsInfo.childs;
      return childs && childs.length ?
          childs.sort((a, b) => {
              return (b.disabled ? 0 : 1) - (a.disabled ? 0 : 1);
          })
          : null
      */
    }
  }
};